import { StoreModel } from '../store.model';
import { MasterDataUploadResponse } from '../../types/master-data-upload-response.types';

export const isLoadingSelector = (state: StoreModel): boolean => state.masterDataUpload.isLoading;

export const responseSelector = (state: StoreModel): MasterDataUploadResponse => state.masterDataUpload.response;

export const isLoadingCommitSelector = (state: StoreModel): boolean => state.masterDataUpload.isLoadingCommit;

export const committedSuccessfullySelector = (state: StoreModel): boolean => state.masterDataUpload.committedSuccessfully;

export const errorCommitSelector = (state: StoreModel): any => state.masterDataUpload.errorCommit;
