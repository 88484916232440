import React from 'react';

import styles from './Header.module.scss';
import logo from '../../assets/images/kingspan-logo.svg';
import { Grid, makeStyles, createStyles, Theme, useMediaQuery } from '@material-ui/core';
import Navigation from './Navigation/Navigation';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    logo: {
      height: 70,
    }
  })
))

export function Header() {

  const classes = useStyles();

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  return (
    <Grid container justify="space-between" alignItems="center" className={styles.header}>
      <Grid>
        <img src={logo} alt="Logo" className={isMobileView ? classes.logo : styles.logo} />
      </Grid>
      <Grid>
        <Navigation />
      </Grid>
    </Grid>
  );
};