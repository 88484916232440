import { MasterDataDownloadModel } from '../store.model';
import {
  MasterDataDownloadActionTypes,
  SET_COUNTRY_ID,
  SET_CONSTRUCTION_TYPE_ID,
  SET_ERROR,
  DOWNLOAD_MASTER_DATA_TEMPLATE_ATTEMPT,
  DOWNLOAD_MASTER_DATA_TEMPLATE_SUCCESS,
  DOWNLOAD_MASTER_DATA_TEMPLATE_FAILURE
} from './master-data-download.action-types';

export const initialState: MasterDataDownloadModel = {
  isLoading: false,
  error: null,
  hasDownloadedSuccessfully: false,
  countryId: '',
  constructionTypeId: '',
};

export function masterDataDownloadReducer(state = initialState, action: MasterDataDownloadActionTypes): MasterDataDownloadModel {
  switch (action.type) {
    case SET_COUNTRY_ID:
      return {
        ...state,
        countryId: action.payload,
      };
    case SET_CONSTRUCTION_TYPE_ID:
      return {
        ...state,
        constructionTypeId: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case DOWNLOAD_MASTER_DATA_TEMPLATE_ATTEMPT:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case DOWNLOAD_MASTER_DATA_TEMPLATE_SUCCESS: 
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case DOWNLOAD_MASTER_DATA_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
