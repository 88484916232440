import React, { forwardRef } from 'react';
import MaterialTable, { Icons } from 'material-table';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import { CircularProgress, Box, Grid } from "@material-ui/core";
import { ArrowUpward, Check } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateConstructionTypeNewsItem, deleteConstructionTypeNewsItem, } from "../../../store/news-items/news-items.action-creators";
import { constructionTypeNewsItemsSelector, countryIdSelector } from "../../../store/news-items/news-items.selectors";
import { ConstructionTypeNewsItemUpdateModel } from "../../../types/domain/news-items.types";
import { useManageAssociationState, Row, unassignedNewsItemId } from "./use-manage-association.state";
import { Trans } from "react-i18next";

const tableIcons: Icons = {
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
};

export default function ManageAssociationTable() {
  const dispatch = useDispatch();
  const constructionTypeNewsItems = useSelector(constructionTypeNewsItemsSelector);
  const selectedCountryId = useSelector(countryIdSelector);

  const { tableData, tableColumns } = useManageAssociationState();

  const onUpdate = (newData: Row): Promise<void> => {
    return new Promise((resolve) => {
      const constructionTypeNewsItem = constructionTypeNewsItems.constructionTypeNewsItemsData.find(item => item.constructionTypeId === newData.constructionTypeId);

      if (newData.newsItemId === unassignedNewsItemId && constructionTypeNewsItem) {
        dispatch(deleteConstructionTypeNewsItem(constructionTypeNewsItem.countryId, constructionTypeNewsItem.constructionTypeId));
      } else if (constructionTypeNewsItem) {
        // item already exists -> update
        const payload: ConstructionTypeNewsItemUpdateModel = {
          constructionTypeId: constructionTypeNewsItem.constructionTypeId,
          countryId: constructionTypeNewsItem.countryId,
          newsItemId: newData.newsItemId
        };
        dispatch(createOrUpdateConstructionTypeNewsItem(payload));
      } else if (newData.newsItemId !== unassignedNewsItemId){
        // create new item
        const payload: ConstructionTypeNewsItemUpdateModel = {
          constructionTypeId: newData.constructionTypeId,
          countryId: selectedCountryId,
          newsItemId: newData.newsItemId
        };
        dispatch(createOrUpdateConstructionTypeNewsItem(payload));
      }
      resolve();
    });
  }

  return (
    <Grid item xs={12}>
      {constructionTypeNewsItems.isLoading && <Box m={2}><CircularProgress/></Box>}
      {!constructionTypeNewsItems.isLoading && <Box mt={3}>
        <MaterialTable
          title={
            <Trans i18nKey={"news-items-ui.associate-news-table"}>
              Associate News Items
            </Trans>}
          icons={tableIcons}
          columns={tableColumns}
          data={tableData}
          options={{
            paging: false,
            actionsColumnIndex: -1
          }}
          editable={{
            onRowUpdate: (newData, _) => onUpdate(newData)
          }}
        />
      </Box>
      }
    </Grid>
  );
}
