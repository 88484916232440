import {ConstructionTypeNewsItemData, NewsItemData} from "../../types/domain/news-items.types";


export const SET_COUNTRY_ID = '[News Items] Set Country Id';
export const SET_ALLOWED_CULTURE_IDS = '[News Items] Set Allowed Culture Id';
export const SET_ERROR = '[News Items] Set Error';

export const GET_NEWS_ITEM_ATTEMPT = '[News Items] Get News Items Attempt';
export const GET_NEWS_ITEM_SUCCESS = '[News Items] Get News Items Success';
export const GET_NEWS_ITEM_FAILURE = '[News Items] Get News Items Failure';

export const GET_CONSTRUCTION_TYPE_NEWS_ITEMS_ATTEMPT = '[News Items] Get Construction Type News Items Attempt';
export const GET_CONSTRUCTION_TYPE_NEWS_ITEMS_SUCCESS = '[News Items] Get Construction Type News Items Success';
export const GET_CONSTRUCTION_TYPE_NEWS_ITEMS_FAILURE = '[News Items] Get Construction Type News Items Failure';

export interface SetCountryId {
  type: typeof SET_COUNTRY_ID;
  payload: string;
}

export interface SetAllowedCulturesId {
  type: typeof SET_ALLOWED_CULTURE_IDS;
  payload: string[];
}

export interface SetError {
  type: typeof SET_ERROR;
  error: any;
}

export interface GetNewsItemsAttempt {
  type: typeof GET_NEWS_ITEM_ATTEMPT;
}

export interface GetNewsItemsSuccess {
  type: typeof GET_NEWS_ITEM_SUCCESS;
  payload: NewsItemData[]
}

export interface GetNewsItemsFailure {
  type: typeof GET_NEWS_ITEM_FAILURE;
  error: any;
}

export interface GetConstructionTypeNewsItemsAttempt {
  type: typeof GET_CONSTRUCTION_TYPE_NEWS_ITEMS_ATTEMPT;
}

export interface GetConstructionTypeNewsItemsSuccess {
  type: typeof GET_CONSTRUCTION_TYPE_NEWS_ITEMS_SUCCESS;
  payload: ConstructionTypeNewsItemData[]
}

export interface GetConstructionTypeNewsItemsFailure {
  type: typeof GET_CONSTRUCTION_TYPE_NEWS_ITEMS_FAILURE;
  error: any;
}

export type NewsItemsActionTypes =
  | SetCountryId
  | SetError
  | SetAllowedCulturesId
  | GetNewsItemsAttempt
  | GetNewsItemsSuccess
  | GetNewsItemsFailure
  | GetConstructionTypeNewsItemsAttempt
  | GetConstructionTypeNewsItemsSuccess
  | GetConstructionTypeNewsItemsFailure;
