import React from 'react';
import { Grid, Avatar, Link, makeStyles, createStyles, Theme } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    signInLinkContainer: {
      marginRight: theme.spacing(2),
    },
    signedInAvatar: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    signedInWelcome: {
      marginRight: theme.spacing(2),
    },
  })
))

export type NavigationTableProps = {
  isLoggedIn: boolean;
  userName: string;
  handleSignInClick: () => void;
  handleSignOutClick: () => void;
};

export default function NavigationTable(props: NavigationTableProps) {

  const { isLoggedIn, userName, handleSignInClick, handleSignOutClick } = props;

  const classes = useStyles();

  return isLoggedIn
    ? <Grid container alignItems="center" onClick={handleSignOutClick}>
      <Grid  className={classes.signedInWelcome}>
        <div data-qa-id="greeting">Welcome, {userName}</div>
        <div style={{float: 'right'}}><Link component="button" variant="body2">Sign out</Link></div>
      </Grid>
      <Grid>
        <Avatar src="not-valid.jpg" className={classes.signedInAvatar}>{getInitials(userName)}</Avatar>
      </Grid>
    </Grid>
    : <Grid container alignItems="center" onClick={handleSignInClick}>
      <Grid className={classes.signInLinkContainer}>
        <Link data-qa-id="signInButtonTablet" component="button" variant="body2">Sign In</Link>
      </Grid>
      <Grid>
        <Avatar src="not-valid.jpg"></Avatar>
      </Grid>
    </Grid>;
}

const getInitials = (name: string): string => {
  return name[0];
};
