import React from 'react';
import { AppBar, Tabs, Tab, Box, Grid } from '@material-ui/core';
import MasterDataDownload from '../MasterData/MasterDataDownload';
import MasterDataUpload from '../MasterData/MasterDataUpload';
import CalculationsExport from '../CalculationsExport/CalculationsExport';
import NewsItems from '../NewsItems/NewsItems';
import { useTranslation } from 'react-i18next';
import { CountryList } from '../Countries/CountryList';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SiteTabs() {

  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label={t('site-tabs-ui.master-data-tab')} {...a11yProps(0)} data-qa-id="master-data-tab" />
          <Tab label={t('site-tabs-ui.calculations-export-tab')} {...a11yProps(1)} data-qa-id="calculations-export-tab" />
          <Tab label={t('site-tabs-ui.news-items-tab')} {...a11yProps(2)} data-qa-id="news-items-tab" />
          <Tab label={t('site-tabs-ui.countries-tab')} {...a11yProps(3)} data-qa-id="countries-tab" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <MasterDataDownload />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MasterDataUpload />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CalculationsExport />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <NewsItems />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CountryList />
      </TabPanel>
    </Box>
  );
}
