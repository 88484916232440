import { AppConfig } from '../types/config/configTypes';

let appConfig: AppConfig = {
  azureAdConfig: {
    tenantId: 'tenantId-not-set',
    clientId: 'clientId-not-set',
  },
  supportedCulturesPerCountry: {

  }
};

export function setAppConfig(newAppConfig: AppConfig) {
  appConfig = newAppConfig;
}

export function getAppConfig() {
  return appConfig;
}
