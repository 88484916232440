import { isEmpty } from 'lodash';
import axios from 'axios';
import { getAuthProvider } from '../../authentication/authProvider';
import {
  setError,
  downloadMasterDataTemplateAttempt,
  downloadMasterDataTemplateFailure,
  downloadMasterDataTemplateSuccess,
} from './master-data-download.actions';

export const downloadMasterDataTemplate = (countryId: string, constructionTypeId: string) => (
  async (dispatch: any) => {
    if (isEmpty(countryId) || isEmpty(constructionTypeId)) {
      dispatch(setError({ message: 'Please select country and construction type before downloading' }));
      return;
    }

    dispatch(downloadMasterDataTemplateAttempt());

    try {
      const token = await getAuthProvider().getIdToken();
      const { data } = await axios.get(`api/MasterData/${constructionTypeId}/${countryId}`, {
        headers: { 'Authorization': `Bearer ${token.idToken.rawIdToken}` },
        responseType: 'blob'
      });

      // Creating link to the Blob
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Materials-${countryId.toUpperCase()}-${constructionTypeId}.xlsx`);
      document.body.appendChild(link);
      // @ts-ignore
      if (window.Cypress) {
        // NOTE: needed for Automated tests, do not remove
        return;
      }
      link.click();
      link.parentNode?.removeChild(link);

      dispatch(downloadMasterDataTemplateSuccess());
    } catch(error) {
      dispatch(downloadMasterDataTemplateFailure(error));
    }

  }
)
