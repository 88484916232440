import {
  GET_CONSTRUCTION_TYPE_NEWS_ITEMS_ATTEMPT,
  GET_CONSTRUCTION_TYPE_NEWS_ITEMS_FAILURE,
  GET_CONSTRUCTION_TYPE_NEWS_ITEMS_SUCCESS,
  GET_NEWS_ITEM_ATTEMPT,
  GET_NEWS_ITEM_FAILURE,
  GET_NEWS_ITEM_SUCCESS,
  GetConstructionTypeNewsItemsAttempt,
  GetConstructionTypeNewsItemsFailure,
  GetConstructionTypeNewsItemsSuccess,
  GetNewsItemsAttempt,
  GetNewsItemsFailure,
  GetNewsItemsSuccess,
  SET_ALLOWED_CULTURE_IDS,
  SET_COUNTRY_ID,
  SET_ERROR,
  SetAllowedCulturesId,
  SetCountryId,
  SetError,
} from "./news-items.action-types";
import {ConstructionTypeNewsItemData, NewsItemData} from "../../types/domain/news-items.types";


export const setCountryId = (countryId: string): SetCountryId => ({
  type: SET_COUNTRY_ID,
  payload: countryId,
});

export const setAllowedCulturesId = (allowedCultures: string[]): SetAllowedCulturesId => ({
  type: SET_ALLOWED_CULTURE_IDS,
  payload: allowedCultures,
});

export const setError = (error: any): SetError => ({
  type: SET_ERROR,
  error: error,
});

export const getNewsItemsAttempt = (): GetNewsItemsAttempt => ({
  type: GET_NEWS_ITEM_ATTEMPT
});

export const getNewsItemsSuccess = (newsItemsData: NewsItemData[]): GetNewsItemsSuccess => ({
  type: GET_NEWS_ITEM_SUCCESS,
  payload: newsItemsData
});

export const getNewsItemsFailure = (error: any): GetNewsItemsFailure => ({
  type: GET_NEWS_ITEM_FAILURE,
  error: error
});

export const getConstructionTypeNewsItemsAttempt = (): GetConstructionTypeNewsItemsAttempt => ({
  type: GET_CONSTRUCTION_TYPE_NEWS_ITEMS_ATTEMPT
});

export const getConstructionTypeNewsItemsSuccess = (constructionTypeNewsItemData: ConstructionTypeNewsItemData[]):
  GetConstructionTypeNewsItemsSuccess => ({
  type: GET_CONSTRUCTION_TYPE_NEWS_ITEMS_SUCCESS,
  payload: constructionTypeNewsItemData
});

export const getConstructionTypeNewsItemsFailure = (error: any): GetConstructionTypeNewsItemsFailure => ({
  type: GET_CONSTRUCTION_TYPE_NEWS_ITEMS_FAILURE,
  error: error
});
