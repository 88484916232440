import {
  UPLOAD_MASTER_DATA_ATTEMPT,
  UploadMasterDataAttempt,
  UPLOAD_MASTER_DATA_SUCCESS,
  UploadMasterDataSuccess,
  UploadMasterDataFailure,
  UPLOAD_MASTER_DATA_FAILURE,
  UploadMasterDataBadRequest,
  UPLOAD_MASTER_DATA_BAD_REQUEST,
  COMMIT_MASTER_DATA_ATTEMPT,
  CommitMasterDataAttempt,
  CommitMasterDataSuccess,
  COMMIT_MASTER_DATA_SUCCESS,
  CommitMasterDataFailure,
  COMMIT_MASTER_DATA_FAILURE
} from './master-data-upload.action-types';
import { BadRequest, Ok } from '../../types/master-data-upload-response.types';

export const uploadMasterDataAttempt = (): UploadMasterDataAttempt => ({
  type: UPLOAD_MASTER_DATA_ATTEMPT,
});

export const uploadMasterDataSuccess = (data: Ok): UploadMasterDataSuccess => ({
  type: UPLOAD_MASTER_DATA_SUCCESS,
  payload: data,
});

export const uploadMasterDataBadRequest = (data: BadRequest): UploadMasterDataBadRequest => ({
  type: UPLOAD_MASTER_DATA_BAD_REQUEST,
  payload: data,
});

export const uploadMasterDataFailure = (error: any): UploadMasterDataFailure => ({
  type: UPLOAD_MASTER_DATA_FAILURE,
  error: error,
});

export const commitMasterDataAttempt = (): CommitMasterDataAttempt => ({
  type: COMMIT_MASTER_DATA_ATTEMPT,
});

export const commitMasterDataSuccess = (): CommitMasterDataSuccess => ({
  type: COMMIT_MASTER_DATA_SUCCESS,
});

export const commitMasterDataFailure = (error: any): CommitMasterDataFailure => ({
  type: COMMIT_MASTER_DATA_FAILURE,
  error: error,
});
