import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { getAuthProvider } from '../../authentication/authProvider';
import { store } from '../../store/store';
import SiteTabs from './SiteTabs';

export function LandingPage() {
  
  const handleLogin = (azureADLogin: () => {}) => {
    azureADLogin();
    console.log('User will be redirected to Azure AD Login');
  };

  const { t } = useTranslation();

  return (
    <div>
      <AzureAD provider={getAuthProvider()} reduxStore={store}>
        {
          ({ login, logout, authenticationState, error, accountInfo}: any) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                return <SiteTabs />;
                
              case AuthenticationState.Unauthenticated:
                return (
                  <div>
                    <h1 data-qa-id="titleLabel">{t('landing-page.unauthenticated-title')}</h1>
                    <p>{t('landing-page.subtitle')}</p>
                    <Button data-qa-id="signInButton" variant="contained" color="primary" onClick={() => handleLogin(login)}>
                      {t('landing-page.login-button')}
                    </Button>
                  </div>
                );
              case AuthenticationState.InProgress:
                return (
                  <div>
                    Authenticating...
                  </div>
                );
              default:
                console.log('Authentication Error', error);
                return (
                  <div>
                    Authentication Error
                  </div>
                );
            }
          }
        }
      </AzureAD>
    </div>
  );
}
