import Box from "@material-ui/core/Box";
import React from "react";

import { countryIdsSelector } from '../../../store/user/user.selectors';

import CountrySelection from '../../CountrySelection/CountrySelection';
import { fetchConstructionTypes } from '../../../store/construction-data/construction-data.action-creators';
import {useDispatch, useSelector} from "react-redux";
import {countryIdSelector} from "../../../store/news-items/news-items.selectors";
import {setAllowedCulturesId, setCountryId} from "../../../store/news-items/news-items.actions";
import { getAppConfig } from "../../../config/config";

export function SelectCountry() {
  const dispatch = useDispatch();
  const { supportedCulturesPerCountry } = getAppConfig();

  const countryIds = useSelector(countryIdsSelector);
  const selectedCountryId = useSelector(countryIdSelector);

  const handleCountryChange = React.useCallback((countryId: string) => {
    const allowedCultures = supportedCulturesPerCountry[countryId];

    dispatch(setCountryId(countryId));
    dispatch(fetchConstructionTypes(countryId));
    dispatch(setAllowedCulturesId(allowedCultures));
  }, [dispatch, supportedCulturesPerCountry]);

  React.useEffect(() => {
    if (!selectedCountryId) {
      handleCountryChange(countryIds[0]);
    }
  }, [countryIds, selectedCountryId, handleCountryChange])

  return <Box m={2} mb={4}>
    <CountrySelection
      countryIds={countryIds}
      selectedCountryId={selectedCountryId}
      handleChange={e => handleCountryChange(e.target.value as string)}
    />
  </Box>
}
