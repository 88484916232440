import { combineReducers } from 'redux';
import { userReducer } from './user/user.reducer';
import { constructionDataReducer } from './construction-data/construction-data.reducer';
import { masterDataDownloadReducer } from './master-data-download/master-data-download.reducer';
import { masterDataUploadReducer } from './master-data-upload/master-data-upload.reducer';
import { calculationsExportReducer } from './calculations-export/calculations-export.reducer';
import {newsItemsReducer} from "./news-items/news-items-reducer";

export const reducers = combineReducers({
  user: userReducer,
  constructionData: constructionDataReducer,
  masterDataDownload: masterDataDownloadReducer,
  masterDataUpload: masterDataUploadReducer,
  calculationsExport: calculationsExportReducer,
  newsItems: newsItemsReducer,
});
