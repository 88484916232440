import { Box } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

import ManageCountriesTable from './ManageCountriesTable';

export const CountryList = () => {

  return (
    <Box>
      <h1 data-qa-id="country-list-title-label">
        <Trans i18nKey={"countries-ui.country-list-title"}>Countries</Trans>
      </h1>

      <ManageCountriesTable />

    </Box>
  );
}
