import { BadRequest, Ok } from "../../types/master-data-upload-response.types";

export const UPLOAD_MASTER_DATA_ATTEMPT = '[Master Data Upload] Upload Master Data - Attempt';
export const UPLOAD_MASTER_DATA_SUCCESS = '[Master Data Upload] Upload Master Data - Success';
export const UPLOAD_MASTER_DATA_BAD_REQUEST = 'Master Data Upload] Upload Master Data - Bad Request';
export const UPLOAD_MASTER_DATA_FAILURE = '[Master Data Upload] Upload Master Data - Failure';

export const COMMIT_MASTER_DATA_ATTEMPT = '[Master Data Upload] Commit Master Data - Attempt';
export const COMMIT_MASTER_DATA_SUCCESS = '[Master Data Upload] Commit Master Data - Success';
export const COMMIT_MASTER_DATA_FAILURE = '[Master Data Upload] Commit Master Data - Failure';

export interface UploadMasterDataAttempt {
  type: typeof UPLOAD_MASTER_DATA_ATTEMPT;
}

export interface UploadMasterDataSuccess {
  type: typeof UPLOAD_MASTER_DATA_SUCCESS;
  payload: Ok;
}

export interface UploadMasterDataBadRequest {
  type: typeof UPLOAD_MASTER_DATA_BAD_REQUEST;
  payload: BadRequest;
}

export interface UploadMasterDataFailure {
  type: typeof UPLOAD_MASTER_DATA_FAILURE;
  error: any;
}

export interface CommitMasterDataAttempt {
  type: typeof COMMIT_MASTER_DATA_ATTEMPT;
}

export interface CommitMasterDataSuccess {
  type: typeof COMMIT_MASTER_DATA_SUCCESS;
}

export interface CommitMasterDataFailure {
  type: typeof COMMIT_MASTER_DATA_FAILURE;
  error: any;
}

export type MasterDataUploadActionTypes =
  | UploadMasterDataAttempt
  | UploadMasterDataSuccess
  | UploadMasterDataBadRequest
  | UploadMasterDataFailure
  | CommitMasterDataAttempt
  | CommitMasterDataSuccess
  | CommitMasterDataFailure;