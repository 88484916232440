export type ChangesOutcome = {
  total: number;
  added: number;
  updated?: number;
  deleted: number;
};

export enum ChangesItem {
  MATERIALS = 'materials',
  MATERIAL_LINKS = 'material-links',
  AIR_CAVITY_THICKNESSES = 'air-cavity-thicknesses',
};

export type BadRequest = {
  errorCode?: string;
  args?: { [key: string]: string };
  message?: string;
};

export type Ok = {
  countryId?: string;
  countryName?: string;
  constructionTypeId?: string;
  constructionTypeNameLocalized?: string;
  changes?: {
    [key in ChangesItem]: ChangesOutcome;
  };
};

export type MasterDataUploadResponse = {
  status?: number;
  ok?: Ok;
  badRequest?: BadRequest;
};
