import React from 'react';
import { useMediaQuery, Theme } from '@material-ui/core';
import NavigationMobile from './Navigation.mobile';
import NavigationTable from './Navigation.tablet';
import { getAuthProvider } from '../../../authentication/authProvider';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../store/user/user.selectors';

export default function Navigation() {

  const isMobileView = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

  const user = useSelector(userSelector);

  const handleSignInClick = () => {
    getAuthProvider().login();
  };

  const handleSignOutClick = () => {
    getAuthProvider().logout();
  };

  return isMobileView
    ? <NavigationMobile isLoggedIn={user.isLoggedIn} userName={user.name} handleSignInClick={handleSignInClick} handleSignOutClick={handleSignOutClick} />
    : <NavigationTable isLoggedIn={user.isLoggedIn} userName={user.name} handleSignInClick={handleSignInClick} handleSignOutClick={handleSignOutClick} />
}
