import React, {forwardRef} from 'react';
import MaterialTable, {Icons} from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import Search from '@material-ui/icons/Search';
import {Box, CircularProgress, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
  countryIdSelector,
  newsItemTableStateSelector
} from "../../store/news-items/news-items.selectors";
import {loadNewsItems} from "../../store/news-items/news-items.action-creators";
import {isEmpty} from "lodash";
import {Alert} from "@material-ui/lab";
import {ArrowUpward} from "@material-ui/icons";
import {Trans, useTranslation} from 'react-i18next';


const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => <ArrowUpward {...props} ref={ref}/>),
};

type ManageNewsTableProps = {
  setShowCreateItemDialog: React.Dispatch<React.SetStateAction<boolean>>,
  setEditCreateItemDialog: React.Dispatch<React.SetStateAction<boolean>>,
}

export interface INewsItems {
  newsItemId: string;
  countryId: string;
  newsItemContents: (INewsItemContentsEntity)[];
}

export interface INewsItemContentsEntity {
  newsItemContentId: string;
  culture: string;
  subject: string;
  url: string;
}

export default function ManageNewsTable(props: ManageNewsTableProps) {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const selectedCountryId = useSelector(countryIdSelector);

  const tableState = useSelector(newsItemTableStateSelector);
  const {isLoading, error, newsItemsData} = tableState;

  React.useEffect(() => {
    if (selectedCountryId) {
      dispatch(loadNewsItems());
    }
  }, [dispatch, selectedCountryId])

  const data = newsItemsData.map(news => news.newsItemContents.map(newsItem => {
    const culture = t(`culture-names.${newsItem.culture}`);

    return {
      title: news.title,
      subject: newsItem.subject,
      url: newsItem.url,
      culture: culture
    };
  })).flat();

  return (
    <Grid item xs={12}>
      {isLoading && <Box m={2}><CircularProgress/></Box>}
      {!isLoading && <MaterialTable
        icons={tableIcons}
        title={
          <Trans i18nKey={"news-items-ui.manage-news-table"}>
            Manage News Items
          </Trans>}
        columns={[
          {title: 'Title', field: 'title', defaultGroupOrder: 0},
          {title: 'Subject', field: 'subject'},
          {title: 'URL', field: 'url'},
          {title: 'Language', field: 'culture'},
        ]}
        data={data}
        options={{
          grouping: false,
          paging: false
        }}
        actions={[
          {
            icon: () => <AddBox/>,
            tooltip: 'Create News Item',
            isFreeAction: true,
            onClick: () => props.setShowCreateItemDialog(true)
          },
          {
            icon: () => <Edit/>,
            tooltip: 'Edit News Item',
            isFreeAction: true,
            disabled: data.length === 0,
            onClick: () => props.setEditCreateItemDialog(true)
          }
        ]}
      />
      }
      {!isLoading && !isEmpty(error) && <Box m={2}><Alert severity="warning">{error}</Alert></Box>}
    </Grid>
  );
}
