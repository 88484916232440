import { Box, CircularProgress, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React from 'react';
import { Trans } from 'react-i18next';
import { toast } from 'react-toastify';

import { getAuthProvider } from '../../authentication/authProvider';
import { CountryModel } from './CountryModel';

export type EditCountryDialogProps = {
  country: CountryModel | null;
  setCountryUnderEdit: React.Dispatch<React.SetStateAction<CountryModel | null>>;
  setCountryUpdated: React.Dispatch<React.SetStateAction<CountryModel | null>>;
  onClose: () => void;
};

export const EditCountryDialog = (props: EditCountryDialogProps) => {

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleResultTypeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    props.setCountryUnderEdit((previousCountry) => {
      return {
        ...(previousCountry as CountryModel), resultType: event.target.value as string
      }
    });
  };

  const handleIsVisibleToPublicChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    const isVisibleToPublic: boolean = event.target.value === "true";
    props.setCountryUnderEdit((previousCountry) => {
      return {
        ...(previousCountry as CountryModel), isVisibleToPublic: isVisibleToPublic
      }
    });
  };

  const onSave = async () => {
    setIsLoading(true);
    const token = await getAuthProvider().getIdToken();

    axios.put("/api/countries", props.country, {
      headers: { "Authorization": `Bearer ${token.idToken.rawIdToken}` }
    }).then(function (response) {
      toast.success(props.country?.name + " updated successfully.")
      setIsLoading(false);
      props.setCountryUpdated(props.country);
      props.onClose();
      console.info(response);
    }).catch(function (error) {
      toast.error(error);
      console.error(error);
      setIsLoading(false);
    });
  }

  return (
    <Dialog
      open
      onClose={props.onClose}
      fullWidth
      scroll="paper"
      disableBackdropClick={true}
      aria-labelledby="form-dialog-edit-country"
    >
      <DialogTitle id="form-dialog-edit-country">
        <Trans i18nKey={"countries-ui.edit-country-dialog.title"}>
          Edit Country
        </Trans>
      </DialogTitle>

      {
        isLoading && <Box m={2}><CircularProgress /></Box>
      }

      {
        !isLoading && <>
          <DialogContent>
            <fieldset>
              <legend>
                <Trans i18nKey={"countries-ui.edit-country-dialog.legend"}>
                  Country Details
                </Trans>
              </legend>
              <Grid item xs={12}>

                <FormLabel id="label-country-name">
                  <Trans i18nKey={"countries-ui.edit-country-dialog.country-name"}>
                    Country Name
                  </Trans>
                </FormLabel>
                <Typography variant="subtitle1" gutterBottom component="div" data-qa-id="country-name">
                  {props.country?.name}
                </Typography>

                <FormLabel id="label-country-code">
                  <Trans i18nKey={"countries-ui.edit-country-dialog.country-code"}>
                    Country Code
                  </Trans>
                </FormLabel>
                <Typography variant="subtitle1" gutterBottom component="div" data-qa-id="country-id">
                  {props.country?.countryId}
                </Typography>

                <Box mt={2}>
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label-result-type">
                      <Trans i18nKey={"countries-ui.edit-country-dialog.result-type"}>
                        Result Type
                      </Trans>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label-result-type"
                      name="radio-buttons-group-result-type"
                      data-qa-id="radio-buttons-group-result-type"
                      value={props.country?.resultType}
                      onChange={handleResultTypeChange}
                    >
                      <FormControlLabel value="UValue" control={<Radio />} label="U-value" />
                      <FormControlLabel value="RValue" control={<Radio />} label="Rc-value" />
                    </RadioGroup>
                  </FormControl>
                </Box>

                <Box mt={2}>
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label-is-visible-to-public">
                      <Trans i18nKey={"countries-ui.edit-country-dialog.is-visible-to-public"}>
                        Country visible to the public?
                      </Trans>
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="radio-buttons-group-label-is-visible-to-public"
                      name="radio-buttons-group-is-visible-to-public"
                      data-qa-id="radio-buttons-group-is-visible-to-public"
                      value={props.country?.isVisibleToPublic ? "true" : "false"}
                      onChange={handleIsVisibleToPublicChange}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="Enabled" />
                      <FormControlLabel value="false" control={<Radio />} label="Disabled" />
                    </RadioGroup>
                  </FormControl>

                  <Alert severity="info">
                    <Trans i18nKey={"countries-ui.edit-country-dialog.is-visible-to-public-info"}>
                      When a country is enabled, that country is visible to the public from the customer facing website with a URL
                      containing the country code shown on this page. Changes are applied when you select the save button below.
                    </Trans>
                  </Alert>
                </Box>

              </Grid>
            </fieldset>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              onClick={props.onClose}
              data-qa-id="edit-country-cancel-button">
              <Trans i18nKey={"countries-ui.edit-country-dialog.cancel"}>Cancel</Trans>
            </Button>
            <Button
              onClick={onSave}
              data-qa-id="edit-country-save-button"
              variant="contained"
              color="primary">
              <Trans i18nKey={"countries-ui.edit-country-dialog.save"}>Save</Trans>
            </Button>
          </DialogActions>
        </>
      }
    </Dialog>
  );
};