import React from 'react';
import { BadRequest } from '../../types/master-data-upload-response.types';
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyle = makeStyles((theme: Theme) => (
  createStyles({
    message: {
      color: theme.palette.error.main,
    }
  })
))

export default function ValidationMessage(props: BadRequest) {

  const { message } = props;

  const classes = useStyle();

  return (
    <div>
      <Box color="text.secondary" mb={2}>Your master data has the following validation error:</Box>
      <div className={classes.message}><Alert severity="warning" data-qa-id="upload-message">{message}</Alert></div>
    </div>
  )
}
