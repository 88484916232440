import { Box, CircularProgress, Grid } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getCountries } from '../../common/countries';
import { CountryModel } from './CountryModel';
import { EditCountryDialog } from './EditCountryDialog';

export default function ManageCountriesTable() {

  const { t } = useTranslation();
  const apiErrorMessage = t("countries-ui.countries-table.countries-api-error");

  const [isLoading, setIsLoading] = useState(true);
  const [countryRows, setCountryRows] = useState<CountryModel[]>([]);
  const [showEditCountryDialog, setShowEditCountryDialog] = useState<boolean>(false);
  const [countryUnderEdit, setCountryUnderEdit] = useState<CountryModel | null>(null);
  const [countryUpdated, setCountryUpdated] = useState<CountryModel | null>(null);

  useEffect(() => {
    setIsLoading(true);
    getCountries().then(result => {
      setCountryRows(result.data);
      setIsLoading(false);
    }).catch(error => {
      toast.error(apiErrorMessage);
      console.error(error);
      setIsLoading(false);
    });
  }, [apiErrorMessage, countryUpdated]);

  function editCountry(country: CountryModel): void {
    setCountryUnderEdit(country);
    setShowEditCountryDialog(true);
  };

  return (
    <>

      {
        showEditCountryDialog &&
        <EditCountryDialog
          onClose={() => setShowEditCountryDialog(false)}
          country={countryUnderEdit}
          setCountryUnderEdit={setCountryUnderEdit}
          setCountryUpdated={setCountryUpdated}
        />
      }

      {
        isLoading && <Box><CircularProgress /></Box>
      }

      {
        !isLoading &&
        <Grid>
          <Box>
            <MaterialTable
              columns={[
                { title: t("countries-ui.countries-table.header-name"), field: "name" },
                { title: t("countries-ui.countries-table.header-country-code"), field: "countryId" },
                {
                  title: t("countries-ui.countries-table.header-result-type"),
                  field: "resultType",
                  render: countryRow => t(countryRow.resultType)
                },
                {
                  title: t("countries-ui.countries-table.header-is-visible-to-public"),
                  field: "isVisibleToPublic",
                  render: countryRow => countryRow.isVisibleToPublic ? t("yes") : t("no")
                }
              ]}
              data={countryRows}
              actions={[
                countryRow => ({
                  icon: () => <Edit />,
                  tooltip: countryRow.canEdit
                    ? t('countries-ui.countries-table.edit-button-tootip-default', { countryName: countryRow.name })
                    : t('countries-ui.countries-table.edit-button-tootip-denied', { countryName: countryRow.name }),
                  onClick: (_event: any, data: any): void => editCountry(data),
                  disabled: !countryRow.canEdit
                })
              ]}
              options={{
                actionsColumnIndex: -1,
                search: false,
                sorting: false,
                paging: false,
                toolbar: false,
                draggable: false
              }}
            />
          </Box>
        </Grid>
      }
    </>
  );
};
