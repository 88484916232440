import { CalculationsExportModel } from '../store.model';
import {
  CalculationsExportActionTypes,
  EXPORT_CALCULATIONS_ATTEMPT,
  EXPORT_CALCULATIONS_SUCCESS,
  EXPORT_CALCULATIONS_FAILURE,
  SET_ERROR,
} from './calculations-export.action-types';

export const initialState: CalculationsExportModel = {
  isLoading: false,
  error: null,
  exportRowCount: null,
};

export function calculationsExportReducer(state = initialState, action: CalculationsExportActionTypes): CalculationsExportModel {
  switch (action.type) {
    case EXPORT_CALCULATIONS_ATTEMPT:
      return {
        ...state,
        isLoading: true,
        error: null,
        exportRowCount: null,
      };
    case EXPORT_CALCULATIONS_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        exportRowCount: action.payload,
      };
    case EXPORT_CALCULATIONS_FAILURE: 
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case SET_ERROR: 
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
