import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { constructionTypesSelector } from "../../../store/construction-data/construction-data.selectors";
import {
    constructionTypeNewsItemsSelector,
    countryIdSelector,
    newsItemTableStateSelector
} from "../../../store/news-items/news-items.selectors";
import { loadConstructionTypeNewsItemsList } from "../../../store/news-items/news-items.action-creators";

import { Column } from "material-table";

export interface Row {
    constructionTypeId: string;
    constructionTypeNameLocalized: string;
    newsItemId: string;
}

export interface TableState {
    tableColumns: Array<Column<Row>>;
    tableData: Row[];
}

export const unassignedNewsItemId = 'unassigned-news-item' as string;

export function useManageAssociationState() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allConstructionTypes = useSelector(constructionTypesSelector);
    const selectedCountryId = useSelector(countryIdSelector);
    const constructionTypeNewsItems = useSelector(constructionTypeNewsItemsSelector);
    const newsItemTableState = useSelector(newsItemTableStateSelector);
    const { newsItemsData } = newsItemTableState;

    React.useEffect(() => {
        if (selectedCountryId) {
            if (selectedCountryId) {
                dispatch(loadConstructionTypeNewsItemsList(selectedCountryId));
            }
        }
    }, [dispatch, selectedCountryId]);

    const getAssignedConstructionNewsItems = React.useCallback((): Row[] => {
        return constructionTypeNewsItems.constructionTypeNewsItemsData.map(item => ({
            constructionTypeId: item.constructionTypeId,
            constructionTypeNameLocalized: allConstructionTypes.find(ct => ct.id === item.constructionTypeId)?.constructionTypeNameLocalized ?? item.constructionTypeId,
            newsItemTitle: item.newsItemTitle,
            newsItemId: item.newsItemId
        }));
    }, [constructionTypeNewsItems, allConstructionTypes]);


    const getUnassignedConstructionNewsItems = React.useCallback((): Row[] => {
        const assignedConstructionTypeIds = constructionTypeNewsItems.constructionTypeNewsItemsData.map(x => x.constructionTypeId)
        return allConstructionTypes.filter(c => !assignedConstructionTypeIds.includes(c.id)).map(x => ({
            constructionTypeId: x.id,
            constructionTypeNameLocalized: x.constructionTypeNameLocalized,
            newsItemTitle: '',
            newsItemId: unassignedNewsItemId
        }) as Row);
    }, [constructionTypeNewsItems.constructionTypeNewsItemsData, allConstructionTypes]);

    const getNewsItemLookup = React.useCallback(() => {
        let data: any = { };
        data[unassignedNewsItemId] = t('news-items-ui.unassigned');
        // @ts-ignore
        newsItemsData.forEach(newsItem => data[newsItem.newsItemId] = newsItem.title)
        return data;
    }, [newsItemsData, t])

    const createTableState = React.useCallback((data: Row[], lookupItems: {}): TableState => ({
        tableColumns: [
            {
                title: 'Construction Type',
                field: 'constructionTypeNameLocalized',
                editable: 'never'
            },
            {
                title: 'News Item',
                field: 'newsItemId',
                lookup: lookupItems,
            },
        ],
        tableData: data,
    }), [])

    const [tableState, setTableState] = React.useState<TableState>(createTableState([], {}));

    React.useEffect(() => {
        const newTableState = createTableState([...getAssignedConstructionNewsItems(), ...getUnassignedConstructionNewsItems()], getNewsItemLookup());
        setTableState(newTableState);
    }, [getNewsItemLookup, getAssignedConstructionNewsItems, getUnassignedConstructionNewsItems, createTableState]);

    return tableState;
}
