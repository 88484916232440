import { ConstructionDataModel } from '../store.model';
import {
  ConstructionDataActionTypes,
  FETCH_CONSTRUCTION_TYPES_ATTEMPT,
  FETCH_CONSTRUCTION_TYPES_SUCCESS,
  FETCH_CONSTRUCTION_TYPES_FAILURE,
} from './construction-data.action-types';

export const initialState: ConstructionDataModel = {
  isLoading: false,
  error: {},
  constructionTypes: [],
};

export function constructionDataReducer(state: ConstructionDataModel = initialState, action: ConstructionDataActionTypes): ConstructionDataModel {
  switch (action.type) {
    case FETCH_CONSTRUCTION_TYPES_ATTEMPT:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CONSTRUCTION_TYPES_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        constructionTypes: action.payload,
      };
    case FETCH_CONSTRUCTION_TYPES_FAILURE: 
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default: 
      return state;
  }
};
