export const SET_COUNTRY_ID = '[Master Data Download] Set Country Id';
export const SET_CONSTRUCTION_TYPE_ID = '[Master Data Download] Set Construction Type Id';
export const SET_ERROR = '[Master Data Download] Set Error';

export const DOWNLOAD_MASTER_DATA_TEMPLATE_ATTEMPT = '[Master Data Download] Download Master Data Template Attempt';
export const DOWNLOAD_MASTER_DATA_TEMPLATE_SUCCESS = '[Master Data Download] Download Master Data Template Success';
export const DOWNLOAD_MASTER_DATA_TEMPLATE_FAILURE = '[Master Data Download] Download Master Data Template Failure';

export interface SetCountryId {
  type: typeof SET_COUNTRY_ID;
  payload: string;
}

export interface SetConstructionTypeId {
  type: typeof SET_CONSTRUCTION_TYPE_ID;
  payload: string;
}

export interface SetError {
  type: typeof SET_ERROR;
  error: any;
}

export interface DownloadMasterDataTemplateAttempt {
  type: typeof DOWNLOAD_MASTER_DATA_TEMPLATE_ATTEMPT;
}

export interface DownloadMasterDataTemplateSuccess {
  type: typeof DOWNLOAD_MASTER_DATA_TEMPLATE_SUCCESS;
}

export interface DownloadMasterDataTemplateFailure {
  type: typeof DOWNLOAD_MASTER_DATA_TEMPLATE_FAILURE;
  error: any;
}

export type MasterDataDownloadActionTypes = 
  | SetCountryId
  | SetConstructionTypeId
  | SetError
  | DownloadMasterDataTemplateAttempt
  | DownloadMasterDataTemplateSuccess
  | DownloadMasterDataTemplateFailure;
