import { CalculationsExportRowCount } from '../../types/domain/calculations-export-types';

import {
  ExportCalculationsAttempt,
  EXPORT_CALCULATIONS_ATTEMPT,
  ExportCalculationsSuccess,
  EXPORT_CALCULATIONS_SUCCESS,
  ExportCalculationsFailure,
  EXPORT_CALCULATIONS_FAILURE,
  SetError,
  SET_ERROR,
} from './calculations-export.action-types';

export const exportCalculationsAttempt = (): ExportCalculationsAttempt => ({
  type: EXPORT_CALCULATIONS_ATTEMPT,
});

export const exportCalculationsSuccess = (exportRowCount: CalculationsExportRowCount): ExportCalculationsSuccess => ({
  type: EXPORT_CALCULATIONS_SUCCESS,
  payload: exportRowCount,
});

export const exportCalculationsFailure = (error: string): ExportCalculationsFailure => ({
  type: EXPORT_CALCULATIONS_FAILURE,
  error: error,
});

export const setError = (error: string | null): SetError => ({
  type: SET_ERROR,
  error: error,
});
