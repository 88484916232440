import {
  uploadMasterDataAttempt,
  uploadMasterDataFailure,
  uploadMasterDataSuccess,
  uploadMasterDataBadRequest,
  commitMasterDataAttempt,
  commitMasterDataSuccess,
  commitMasterDataFailure,
} from './master-data-upload.actions';
import axios from 'axios';
import { getAuthProvider } from '../../authentication/authProvider';
import { ResponseCode } from '../../types/response-code.types';

export const uploadMasterData = (file: any, fileName: string, commit: boolean = false) => (
  async (dispatch: (action: any) => void) => {
    if (commit) {
      dispatch(commitMasterDataAttempt())
    } else {
      dispatch(uploadMasterDataAttempt());
    }

    try {
      const formData = new FormData();
      formData.append('masterDataFile', file as string, fileName);

      const token = await getAuthProvider().getIdToken();
      const { data } = await axios.post('api/masterData', formData, {
        headers: {
          'Authorization': `Bearer ${token.idToken.rawIdToken}`,
          'Content-Type': 'multipart/form-data'
        },
        params: {
          commit: commit,
        }
      });
      
      if (commit) {
        dispatch(commitMasterDataSuccess());
      } else {
        dispatch(uploadMasterDataSuccess({ ...data }));
      }

    } catch (error) {
      if (commit) {
        dispatch(commitMasterDataFailure(error));
        return;
      }

      const { status, data } = error.response;

      if (status === ResponseCode.BAD_REQUEST) {
        dispatch(uploadMasterDataBadRequest({
          errorCode: data.errorCode,
          args: data.args,
          message: data.message,
        }));
        
        return;
      }

      dispatch(uploadMasterDataFailure(error.response))
    }
  }
);
