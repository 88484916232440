import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import { reducers } from './reducers';

const middleware: any[] = [thunk];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxImmutableStateInvariant());
}

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));
