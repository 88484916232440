import axios from 'axios';
import {getAuthProvider} from '../../authentication/authProvider';
import {StoreModel} from '../store.model'
import {
  getConstructionTypeNewsItemsAttempt,
  getConstructionTypeNewsItemsFailure,
  getConstructionTypeNewsItemsSuccess,
  getNewsItemsAttempt,
  getNewsItemsFailure,
  getNewsItemsSuccess
} from "./news-items.actions";
import {ConstructionTypeNewsItemUpdateModel, NewsItemData} from "../../types/domain/news-items.types";
import {isEmpty} from "lodash";


export type RequestResult = {
  success: boolean,
  error: string
}

const urlRegex = new RegExp('https?://');

const urlHasProtocol = (url: string) => {
  return urlRegex.test(url);
}

const urlIsBlank = (url: string) => {
  return url.replace(urlRegex, '') === '';
}

export const createOrUpdateNewsItem = (data: NewsItemData) => async (dispatch: any): Promise<RequestResult> => {

  let result: RequestResult = {success: false, error: ""};

  if (isEmpty(data.title)) {
    result.error = 'news-items-ui.validation-errors.missing-title';
    return result;
  }
  
  for (const item of data.newsItemContents) {
    if (isEmpty(item.url) || isEmpty(item.subject)) {
      result.error = 'news-items-ui.validation-errors.empty-fields';
      return result;
    }
    if (!urlHasProtocol(item.url)) {
      result.error = 'news-items-ui.validation-errors.no-protocol';
      return result;
    }
    if (urlIsBlank(item.url)) {
      result.error = 'news-items-ui.validation-errors.empty-url';
      return result;
    }
  }

  try {
    const token = await getAuthProvider().getIdToken();
    const requestResult = await axios.post(`api/NewsItems`, data,
      {
        headers: {
          "Authorization": `Bearer ${token.idToken.rawIdToken}`,
          "Content-Type": "application/json"
        },
      });

    if (requestResult.status === 200) {
      dispatch(loadNewsItems());

      result.success = true;
      return result;
    }
  } catch (error) {
    result.error = error.message;
  }

  return result;
}

export const loadNewsItems = () => async (dispatch: any, getState: () => StoreModel) => {
  const countryId = getState().newsItems.countryId;
  dispatch(getNewsItemsAttempt());
  try {
    const token = await getAuthProvider().getIdToken();
    const requestResult = await axios.get(`/api/NewsItems?countryId=${countryId}`,
      {
        headers: {
          "Authorization": `Bearer ${token.idToken.rawIdToken}`,
          "Content-Type": "application/json"
        },
      });

    if (requestResult.status === 200) {
      dispatch(getNewsItemsSuccess(requestResult.data));
    }
  } catch (error) {
    dispatch(getNewsItemsFailure(error.message));
  }
}

export const deleteNewsItem = (newsItemId: string) => async (dispatch: any): Promise<RequestResult> => {
  let result: RequestResult = {success: false, error: ""};

  try {
    const token = await getAuthProvider().getIdToken();
    const requestResult = await axios.delete(`api/NewsItems/${newsItemId}`,
      {
        headers: {
          "Authorization": `Bearer ${token.idToken.rawIdToken}`,
          "Content-Type": "application/json"
        },
      });

    if (requestResult.status === 200) {
      dispatch(loadNewsItems());

      result.success = true;
      return result;
    }
  } catch (error) {
    result.error = error.message;
  }

  return result;
}

export const loadConstructionTypeNewsItemsList = (countryId: string) => async (dispatch: any) => {
  dispatch(getConstructionTypeNewsItemsAttempt());
  try {
    const token = await getAuthProvider().getIdToken();
    const requestResult = await axios.get(`/api/ConstructionTypesNewsItem?countryId=${countryId}`,
      {
        headers: {
          "Authorization": `Bearer ${token.idToken.rawIdToken}`,
          "Content-Type": "application/json"
        },
      });

    if (requestResult.status === 200) {
      dispatch(getConstructionTypeNewsItemsSuccess(requestResult.data));
    }
  } catch (error) {
    dispatch(getConstructionTypeNewsItemsFailure(error.message));
  }
}

export const createOrUpdateConstructionTypeNewsItem = (data: ConstructionTypeNewsItemUpdateModel) => async (dispatch: any): Promise<RequestResult> => {
  let result: RequestResult = {success: false, error: ""};
  try {
    const token = await getAuthProvider().getIdToken();
    const requestResult = await axios.post(`/api/ConstructionTypesNewsItem`, data,
      {
        headers: {
          "Authorization": `Bearer ${token.idToken.rawIdToken}`,
          "Content-Type": "application/json"
        },
      });

    if (requestResult.status === 200) {
      dispatch(loadConstructionTypeNewsItemsList(data.countryId));

      result.success = true;
      return result;
    }
  } catch (error) {
    result.error = error.message;
  }

  return result;
}

export const deleteConstructionTypeNewsItem = (countryId: string, constructionTypeId: string) => 
  async (dispatch: any): Promise<RequestResult> => {

    let result: RequestResult = {success: false, error: ""};
    try {
      const token = await getAuthProvider().getIdToken();
      const requestResult = await axios.delete(`api/ConstructionTypesNewsItem/${countryId}/${constructionTypeId}`,
      {
        headers: {
          "Authorization": `Bearer ${token.idToken.rawIdToken}`,
          "Content-Type": "application/json"
        },
      });
      if (requestResult.status === 200) {
        dispatch(loadConstructionTypeNewsItemsList(countryId));
  
        result.success = true;
        return result;
      }
    } catch (error) {
      result.error = error.message;
    }

    return result;
  }
