import { AnyAction } from 'redux';
import { UserModel } from '../store.model';
import { AuthenticationActions, IAccountInfo } from 'react-aad-msal';

export const initialState: UserModel = {
  isLoggedIn: false,
  name: '',
  countryIds: [],
  allRoleClaims: [],
};

export function userReducer(state: UserModel = initialState, action: AnyAction) {
  switch (action.type) {
    case AuthenticationActions.LoginSuccess:
      const payload = action.payload as IAccountInfo;
      const account = payload.account;
      
      const countryIds = (account.idToken.roles as unknown as string[])
        .filter((role: string) => role.startsWith('country-'))
        .map((role: string) => role.substring('country-'.length).toUpperCase());

      return {
        ...state,
        isLoggedIn: true,
        name: account.name,
        countryIds,
        allRoleClaims: account.idToken.roles,
      };

    case AuthenticationActions.LogoutSuccess:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
