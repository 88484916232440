import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import { hasTechnicalRoleSelector, countryIdsSelector } from '../../store/user/user.selectors';
import CountrySelection from '../CountrySelection/CountrySelection';
import ConstructionTypeSelection from '../ConstructionTypeSelection/ConstructionTypeSelection';
import {
  countryIdSelector,
  constructionTypeIdSelector,
  errorSelector,
  isLoadingSelector
} from '../../store/master-data-download/master-data-download.selectors';
import { setCountryId, setConstructionTypeId } from '../../store/master-data-download/master-data-download.actions';
import { constructionTypesSelector } from '../../store/construction-data/construction-data.selectors';
import { fetchConstructionTypes } from '../../store/construction-data/construction-data.action-creators';
import { Button, Box, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { downloadMasterDataTemplate } from '../../store/master-data-download/master-data-download.action-creators';
import { isEmpty } from 'lodash';
import MasterDataDownloadAll from './MasterDataDownloadAll';

export default function MasterDataDownload() {

  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const isLoading = useSelector(isLoadingSelector);

  // All the things for CountrySelection
  const countryIds = useSelector(countryIdsSelector);
  const selectedCountryId = useSelector(countryIdSelector);
  const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setConstructionTypeId(''));
    dispatch(setCountryId(event.target.value as string));
  };

  // All the things for ConstructionTypeSelection
  const constructionTypes = useSelector(constructionTypesSelector);
  const selectedConstructionTypeId = useSelector(constructionTypeIdSelector);
  const handleConstructionTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatch(setConstructionTypeId(event.target.value as string));
  };

  const hasTechnicalRole = useSelector(hasTechnicalRoleSelector);

  const handleDownloadOnClick = () => {
    dispatch(downloadMasterDataTemplate(selectedCountryId, selectedConstructionTypeId));
  }

  useEffect(() => {
    if (selectedCountryId) {
      dispatch(fetchConstructionTypes(selectedCountryId));
    }
  }, [selectedCountryId, dispatch]);

  return (
    <div>
      <h1 data-qa-id="titleLabelGetTemplate"><Trans i18nKey={'master-data-download-ui.header'}>download-master-data</Trans></h1>

      {!hasTechnicalRole ? <p><Trans i18nKey={'master-data-download-ui.no-permission'}>no-permission</Trans></p>
        : <div>
          <Box m={2} mb={4}>
            <CountrySelection
              countryIds={countryIds}
              selectedCountryId={selectedCountryId}
              handleChange={handleCountryChange}
            />
          </Box>

          <Box m={2} mb={4}>
            <ConstructionTypeSelection
              constructionTypes={constructionTypes}
              selectedConstructionTypeId={selectedConstructionTypeId}
              handleChange={handleConstructionTypeChange}
              disabled={selectedCountryId.length > 0 ? false : true}
            />
          </Box>

          {isLoading && <Box m={2}><CircularProgress /></Box>}

          <Box m={2}>
            <Button data-qa-id="downloadButton" variant="contained" color="primary" onClick={handleDownloadOnClick} disabled={isLoading}>Download</Button>
          </Box>

          {!isEmpty(error) && <Box m={2}><Alert severity="warning">{error.message}</Alert></Box>}
        </div>}

      <MasterDataDownloadAll />
    </div>
  );
};
