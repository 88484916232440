import {
  FETCH_CONSTRUCTION_TYPES_ATTEMPT,
  FETCH_CONSTRUCTION_TYPES_SUCCESS,
  FETCH_CONSTRUCTION_TYPES_FAILURE,
  FetchConstructionTypesAttempt,
  FetchConstructionTypesSuccess,
  FetchConstructionTypesFailure,
} from './construction-data.action-types';
import { ConstructionType } from '../../types/domain/construction-data.types';

export const fetchConstructionTypesAttempt = (): FetchConstructionTypesAttempt => ({
  type: FETCH_CONSTRUCTION_TYPES_ATTEMPT,
});

export const fetchConstructionTypesSuccess = (data: ConstructionType[]): FetchConstructionTypesSuccess => ({
  type: FETCH_CONSTRUCTION_TYPES_SUCCESS,
  payload: data,
});

export const fetchConstructionTypesFailure = (error: any): FetchConstructionTypesFailure => ({
  type: FETCH_CONSTRUCTION_TYPES_FAILURE,
  error,
});
