import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { countryIdsSelector, hasTechnicalRoleSelector } from '../../store/user/user.selectors';
import { Button, Box, CircularProgress, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAuthProvider } from '../../authentication/authProvider';

export default function MasterDataDownloadAll() {

  const countryIds = useSelector(countryIdsSelector);
  const hasTechnicalRole = useSelector(hasTechnicalRoleSelector);
  const [isDownloadingAll, setDownloadingAll] = useState(false);
  const [downloadAllError, setDownloadAllError] = useState('');

  const handleDownloadAllClick = async () => {
    setDownloadingAll(true);
    setDownloadAllError('');

    var startTimeMs = new Date().getTime();

    try {
      const token = await getAuthProvider().getIdToken();
      const { data } = await axios.get('api/MasterData/zip-all', {
        headers: { 'Authorization': `Bearer ${token.idToken.rawIdToken}` },
        responseType: 'blob'
      });

      var endTimeMs = new Date().getTime();
      var durationMs = endTimeMs - startTimeMs;
      var durationSeconds1dp = Math.round(durationMs / 100) / 10;
      toast.success(`Downloaded Materials Master Data for countries ${countryIds.join(', ')} in ${durationSeconds1dp} seconds.`);

      // Creating link to the Blob
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Materials-All-${countryIds.join('-')}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      setDownloadingAll(false);
    } catch(error) {
      setDownloadingAll(false);
      setDownloadAllError(error?.toString() || 'Unknown error');
    }
  }

  return (
    <div>
      {countryIds.length > 0 && hasTechnicalRole && <div>
        <Divider />
        <h1>Download all master data</h1>
        <Box m={2}>
          <p>
            <Button variant="contained" color="primary" onClick={handleDownloadAllClick} disabled={isDownloadingAll}>Download All</Button>
            {isDownloadingAll && <Box m={2}><CircularProgress /></Box>}
          </p>
          <Alert severity="info">
            Downloads a zip file containing the Master Data for all of the countries you have access to.
            <br /><br />
            You can access: {countryIds.join(', ')}
          </Alert>
        </Box>
        {!isEmpty(downloadAllError) && <Box m={2}><Alert severity="error">{downloadAllError}</Alert></Box>}
      </div>}
    </div>
  );
};
