import React from 'react';
import { Box, CircularProgress, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';

export type ChooseFileProps = {
  fileName: string;
  isLoading: boolean;
  isSecondary: boolean;
  handleChange: (event: any) => void;
  committedSuccessfully?: boolean;
};

export default function ChooseFile(props: ChooseFileProps) {

  const { fileName, isLoading, isSecondary, handleChange, committedSuccessfully } = props;

  return (
    <Box>
      {isEmpty(fileName) || committedSuccessfully
        ? <Box my={2}>No file selected</Box>
        : <Box my={2}>File name: {fileName}</Box>
      }

      {isLoading && <Box m={2}><CircularProgress /></Box>}

      <Box my={2}>
        <Button
          variant={isSecondary ? 'outlined' : 'contained'}
          color={isSecondary ? 'secondary' : 'primary'}
          component="label" disabled={isLoading}
          data-qa-id="choose-file-btn"
        >
          {isSecondary
            ? <span>Choose a different file</span>
            : <span>Choose file</span>
          }
          <input
            type="file"
            style={{ display: "none" }}
            onChange={handleChange}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
        </Button>
      </Box>
    </Box>
  );
};
