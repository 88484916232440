import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans, useTranslation } from "react-i18next";
import { Box, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { newsItemTableStateSelector } from "../../../store/news-items/news-items.selectors";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { createOrUpdateNewsItem, deleteNewsItem } from "../../../store/news-items/news-items.action-creators";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { isEmpty } from "lodash";
import { Alert } from "@material-ui/lab";
import DialogContentText from "@material-ui/core/DialogContentText";
import { NewsItemData } from "../../../types/domain/news-items.types";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export type EditNewsItemDialogProps = {
  onClose: () => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancel: {
      flex: 1,
      alignSelf: "flex-end",
      justifyContent: "left"
    },
    formControl: {
      marginBottom: theme.spacing(5),
    },
    primaryAction: {
      minWidth: "120px"
    }
  })
);

export const EditNewsItemDialog = (props: EditNewsItemDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const dispatch: AppDispatch = useDispatch();
  const tableState = useSelector(newsItemTableStateSelector);

  const { newsItemsData } = tableState;

  const [selectedNewsItem, setSelectedNewsItem] = React.useState<NewsItemData>();
  const [requestResult, setRequestResult] = React.useState({ isLoading: false, error: "" });
  const [showDeleteView, setShowDeleteView] = React.useState(false);

  const { isLoading, error } = requestResult;

  const onSelectNewsItem = (newsItemId: string) => {
    const newsItems = newsItemsData.find(x => x.newsItemId === newsItemId);
    setSelectedNewsItem(newsItems);
  }

  const onUpdateNewsTitle = (newTitle: string) => {
    setSelectedNewsItem(prevState => {
      if (prevState) {
        return { ...prevState, title: newTitle }
      }
      return prevState;
    });
  }

  const onUpdateNewsField = (newsItemContentId: string, updatedField: object) => {
    setSelectedNewsItem(prevState => {
      if (prevState) {
        let newsItemContentIdx = prevState.newsItemContents.findIndex(item => item.newsItemContentId === newsItemContentId);
        const newsItemContent = prevState.newsItemContents[newsItemContentIdx];
        return {
          ...prevState,
          newsItemContents: [
            ...prevState.newsItemContents.slice(0, newsItemContentIdx),
            { ...newsItemContent, ...updatedField },
            ...prevState.newsItemContents.slice(newsItemContentIdx + 1)
          ]
        }
      }
      return prevState;
    });
  }

  const onSubmit = async () => {
    if (!requestResult.isLoading) {
      setRequestResult(prevState => ({ ...prevState, isLoading: true }))
      if (selectedNewsItem) {
        let result = await dispatch(createOrUpdateNewsItem(selectedNewsItem));
        if (result.success) {
          props.onClose()
        } else {
          setRequestResult(prevState => ({ ...prevState, isLoading: false, error: t(result.error), success: result.success }))
        }
      }
    }
  }

  const onDeleteItem = async () => {
    if (!requestResult.isLoading && selectedNewsItem) {
      setRequestResult(prevState => ({ ...prevState, isLoading: true }))

      let result = await dispatch(deleteNewsItem(selectedNewsItem.newsItemId));

      if (result.success) {
        props.onClose()
      } else {
        setRequestResult(prevState => ({ ...prevState, isLoading: false, error: result.error }))
      }
    }
  }

  return (
    <Dialog
      open
      onClose={props.onClose}
      fullWidth
      scroll="paper"
      disableBackdropClick={true}
      aria-labelledby="form-dialog-edit"
    >
      <DialogTitle id="form-dialog-edit">
        <Trans i18nKey={"news-items-ui.edit-news.modal-title"}>
          Edit News Item
        </Trans>
      </DialogTitle>
      {!showDeleteView &&
        <DialogContent>
          {!selectedNewsItem &&
            <div>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="title-select-label">
                  <Trans i18nKey={"news-items-ui.news-modal-select-title"}>
                    Select News Title
              </Trans>
                </InputLabel>
                <Select
                  labelId="title-select-label"
                  autoWidth={true}
                  onChange={e => onSelectNewsItem(e.target.value as string)}
                >
                  {newsItemsData.map(newsItem => (
                    <MenuItem key={newsItem.newsItemId} value={newsItem.newsItemId}>
                      {newsItem.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DialogActions>
                <Button
                  disabled={isLoading}
                  color="primary"
                  onClick={props.onClose}
                >
                  <Trans i18nKey={"news-items-ui.news-modal-cancel"}>Cancel</Trans>
                </Button>

              </DialogActions>
            </div>}
          {selectedNewsItem && <div>
            <>
              <TextField
                margin="dense"
                id="news-title"
                variant="outlined"
                value={selectedNewsItem.title}
                label={t("news-items-ui.news-model-title")}
                onChange={e => onUpdateNewsTitle(e.target.value as string)}
                fullWidth
              />
              {selectedNewsItem.newsItemContents.map(item => {
                return (
                  <fieldset>
                    <legend>{t(`culture-names.${item.culture}`)}</legend>
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        id="subject"
                        variant="outlined"
                        multiline
                        value={item.subject}
                        label={t("news-items-ui.news-modal-subject")}
                        onChange={e => onUpdateNewsField(item.newsItemContentId, { subject: e.target.value as string })}
                        fullWidth
                      />
                      <TextField
                        margin="dense"
                        id="content-url"
                        variant="outlined"
                        value={item.url}
                        label={t("news-items-ui.news-modal-url")}
                        onChange={e => onUpdateNewsField(item.newsItemContentId, { url: e.target.value as string })}
                        fullWidth
                      />
                    </Grid>
                  </fieldset>
                )
              })}
              <DialogActions>
                <Button
                  disabled={isLoading}
                  color="primary"
                  onClick={props.onClose}
                  className={classes.cancel}
                >
                  <Trans i18nKey={"news-items-ui.news-modal-cancel"}>Cancel</Trans>
                </Button>
                <Button
                  disabled={isLoading}
                  color="primary"
                  variant="outlined"
                  onClick={() => setShowDeleteView(true)}
                >
                  <Trans i18nKey={"news-items-ui.news-modal-delete"}>
                    Delete
                </Trans>
                </Button>
                <Button disabled={isLoading} onClick={onSubmit} variant="contained" className={classes.primaryAction}
                  color="primary">
                  <Trans i18nKey={"news-items-ui.news-modal-save"}>Save</Trans>
                </Button>
              </DialogActions>
            </>
          </div>}
          {!isLoading && !isEmpty(error) && <Box m={2}><Alert severity="warning">{error}</Alert></Box>}
        </DialogContent>
      }
      {showDeleteView && selectedNewsItem &&
        <div>
          <DialogContent>
            <DialogContentText id="delete-dialog-description">
            <Trans i18nKey='news-items-ui.edit-news.delete-prompt'> 
            You are about to delete the following Item: </Trans><Box display="inline"
                fontWeight="fontWeightBold">{selectedNewsItem.title}</Box>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDeleteView(false)} color="primary">
              Cancel
          </Button>
            <Button onClick={onDeleteItem} variant="outlined" className={classes.primaryAction} color="primary" autoFocus>
              Ok
          </Button>
          </DialogActions>
        </div>}
    </Dialog>
  );
};
