import React from 'react';
import { Ok, ChangesItem } from '../../types/master-data-upload-response.types';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import ArrowRight from '@material-ui/icons/ArrowRight';

export default function CommitMessage(props: Ok) {

  const { changes } = props;

  const { t } = useTranslation();

  const countryConstructionTypeMessage = props.countryId && props.constructionTypeId &&
    `${t('master-data-upload-messages.ok.countryConstructionTypeWillBeUpdated', {
      country: props.countryName,
      constructionType: props.constructionTypeNameLocalized
    })}`;

  const renderChanges = (item: ChangesItem) => {
    const change = changes![item];
    return Object.keys(change).filter(key => key !== 'softDeleted' && key !== 'hardDeleted').map((key, index) => {
      // @ts-ignore
      const count = change[key];
      return (
        <ListItem key={`${item}.${key}`}>
          <ListItemIcon><ArrowRight /></ListItemIcon>
          <ListItemText
            primary={t(`master-data-upload-messages.ok.${item}.${key}`, { count })}
          />
        </ListItem>
      );
    });
  }

  return (
    <Box>
      <Box color="text.secondary" mb={2}>Before you commit consider the following:</Box>
      <Box mb={2}>
        <List dense={true}>
          {countryConstructionTypeMessage && <ListItem>
            <ListItemIcon><ArrowRight /></ListItemIcon>
            <ListItemText primary={countryConstructionTypeMessage} />
          </ListItem>}
        </List>
      </Box>
      <Box mb={2}>
        <List dense={true} data-qa-id="materials">
          {renderChanges(ChangesItem.MATERIALS)}
        </List>
      </Box>
      <Box mb={2}>
        <List dense={true} data-qa-id="materials-links">
          {renderChanges(ChangesItem.MATERIAL_LINKS)}
        </List>
      </Box>
      <Box mb={2}>
        <List dense={true} data-qa-id="air-cavity-thicknesses">
          {renderChanges(ChangesItem.AIR_CAVITY_THICKNESSES)}
        </List>
      </Box>
    </Box >
  );
}
