import { MasterDataUploadModel } from '../store.model';
import {
  MasterDataUploadActionTypes,
  UPLOAD_MASTER_DATA_SUCCESS,
  UPLOAD_MASTER_DATA_FAILURE,
  UPLOAD_MASTER_DATA_ATTEMPT,
  UPLOAD_MASTER_DATA_BAD_REQUEST,
  COMMIT_MASTER_DATA_ATTEMPT,
  COMMIT_MASTER_DATA_SUCCESS,
  COMMIT_MASTER_DATA_FAILURE
} from './master-data-upload.action-types';
import { ResponseCode } from '../../types/response-code.types';

export const initialState: MasterDataUploadModel = {
  isLoading: false,
  error: null,
  isLoadingCommit: false,
  errorCommit: null,
  response: {},
  committedSuccessfully: false,
};

export function masterDataUploadReducer(
  state: MasterDataUploadModel = initialState,
  action: MasterDataUploadActionTypes
): MasterDataUploadModel {
  switch (action.type) {
    case UPLOAD_MASTER_DATA_ATTEMPT:
      return {
        ...state,
        isLoading: true,
        error: null,
        committedSuccessfully: false,
      };
    case UPLOAD_MASTER_DATA_SUCCESS: 
      return {
        ...state,
        isLoading: false,
        response: {
          status: ResponseCode.OK,
          ok: action.payload,
        },
      };
    case UPLOAD_MASTER_DATA_BAD_REQUEST:
      return {
        ...state,
        isLoading: false,
        response: {
          status: ResponseCode.BAD_REQUEST,
          badRequest: action.payload,
        },
      };
    case UPLOAD_MASTER_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case COMMIT_MASTER_DATA_ATTEMPT:
      return {
        ...state,
        isLoadingCommit: true,
      };
    case COMMIT_MASTER_DATA_SUCCESS:
      return {
        ...state,
        isLoadingCommit: false,
        errorCommit: null,
        committedSuccessfully: true,
        response: {},
      };
    case COMMIT_MASTER_DATA_FAILURE:
      return {
        ...state,
        isLoadingCommit: false,
        errorCommit: action.error,
      };
    default:
      return state;
  }
}