import { InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ConstructionType } from '../../types/domain/construction-data.types';

export type ConstructionTypeSelectionPropType = {
  constructionTypes: ConstructionType[];
  selectedConstructionTypeId: string;
  disabled: boolean;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
};

export default function ConstructionTypeSelection(props: ConstructionTypeSelectionPropType) {

  const { t } = useTranslation();

  return (
    <div>
      <InputLabel id="construction-type-select-label">
        <Trans i18nKey={'construction-type-selection-ui.select-label'}>Construction Type</Trans>
      </InputLabel>
      <Select
        disabled={props.disabled}
        labelId="construction-type-select-label"
        data-qa-id="constructionTypeSelect"
        displayEmpty
        value={props.selectedConstructionTypeId}
        onChange={props.handleChange}
        renderValue={() => {
          if (props.selectedConstructionTypeId.length === 0) {
            return <em>{t('construction-type-selection-ui.select-default')}</em>;
          }
          return props.constructionTypes.find(ct => ct.id === props.selectedConstructionTypeId)?.constructionTypeNameLocalized;
        }}
      >
        <MenuItem disabled value="">
          <em>{t('construction-type-selection-ui.select-default')}</em>
        </MenuItem>

        {props.constructionTypes.map((ct: ConstructionType) => (
          <MenuItem key={ct.id} value={ct.id}>
            {ct.constructionTypeNameLocalized}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
