import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans, useTranslation } from "react-i18next";
import { uuid } from "../../../common/uuid";
import { Box, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { allowedCultureIdsSelector, countryIdSelector, } from "../../../store/news-items/news-items.selectors";
import { createOrUpdateNewsItem } from "../../../store/news-items/news-items.action-creators";
import { isEmpty } from "lodash";
import { Alert } from "@material-ui/lab";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { NewsItemContent, NewsItemData } from "../../../types/domain/news-items.types";

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

const createNewsItemModel = (allowedCultureIds: string[]): { [newsItemContentId: string]: NewsItemContent } => {
  return allowedCultureIds.map(cultureId => ({
    newsItemContentId: uuid(),
    culture: cultureId,
    subject: "",
    url: ""
  } as NewsItemContent)).reduce((obj, item) => {
    return {
      ...obj,
      [item.newsItemContentId]: item
    };
  }, {});
}

export type CreateNewsItemDialogProps = {
  onClose: () => void,
};

export const CreateNewsItemDialog = (props: CreateNewsItemDialogProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const selectedCountryId = useSelector(countryIdSelector);
  const allowedCultureIds = useSelector(allowedCultureIdsSelector);

  const [title, setTitle] = React.useState("");
  const [newsItemContents, setNewsItemContents] = React.useState(createNewsItemModel(allowedCultureIds));
  const [requestResult, setRequestResult] = React.useState({ isLoading: false, error: "" });

  const { isLoading, error } = requestResult;

  const onUpdateItem = (id: string, updatedField: object) => {
    const newsItem = newsItemContents[id];

    setNewsItemContents((prevState) => {
      return {
        ...prevState,
        [newsItem.newsItemContentId]: {
          ...newsItem,
          ...updatedField
        }
      };
    });
  };

  const onSubmit = async () => {
    if (!requestResult.isLoading) {
      setRequestResult(prevState => ({ ...prevState, isLoading: true }))

      const data = {
        title: title,
        countryId: selectedCountryId,
        newsItemContents: Object.values(newsItemContents).map(item => (
          {
            culture: item.culture,
            subject: item.subject,
            url: item.url
          } as NewsItemContent
        ))
      } as NewsItemData;

      let result = await dispatch(createOrUpdateNewsItem(data));

      if (result.success) {
        props.onClose()
      } else {
        setRequestResult(prevState => ({ ...prevState, isLoading: false, error: t(result.error) }))
      }
    }
  }

  const newsItems = Object.values(newsItemContents).map((item) => {
    return (
      <fieldset key={item.newsItemContentId}>
        <legend>{t(`culture-names.${item.culture}`)}</legend>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            variant="outlined"
            multiline
            value={item.subject}
            label={t("news-items-ui.news-modal-subject")}
            onChange={e => onUpdateItem(item.newsItemContentId, { subject: e.target.value as string })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            variant="outlined"
            value={item.url}
            label={t("news-items-ui.news-modal-url")}
            onChange={e => onUpdateItem(item.newsItemContentId, { url: e.target.value as string })}
            fullWidth
          />
        </Grid>
      </fieldset>
    );
  })

  return (
    <Dialog
      open
      onClose={props.onClose}
      fullWidth
      scroll="paper"
      disableEscapeKeyDown={isLoading}
      disableBackdropClick={isLoading}
      aria-labelledby="form-dialog-create"
    >
      <DialogTitle id="form-dialog-create">
        <Trans i18nKey={"news-items-ui.create-news-modal-title"}>
          Create News item
        </Trans>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="news-title"
          variant="outlined"
          value={title}
          label={t("news-items-ui.news-model-title")}
          onChange={e => setTitle(e.target.value as string)}
          fullWidth
        />
        {newsItems}
        {!isEmpty(error) && <Box m={2}><Alert severity="warning">{error}</Alert></Box>}
      </DialogContent>
      <DialogActions style={{ margin: 10 }}>
        <Button
          disabled={isLoading}
          color="primary"
          variant="outlined"
          onClick={props.onClose}
        >
          <Trans i18nKey={"news-items-ui.news-modal-cancel"}>Cancel</Trans>
        </Button>
        <Button disabled={isLoading} onClick={onSubmit} variant="contained" color="primary">
          <Trans i18nKey={"news-items-ui.news-modal-create"}>Create</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
