import { MsalAuthProvider, LoginType, IMsalAuthProviderConfig } from 'react-aad-msal';
import { Configuration, CacheLocation } from 'msal';
import { getAppConfig } from '../config/config';

let authProvider: MsalAuthProvider | null = null;

const createAuthProvider = (): MsalAuthProvider => {
  const cacheLocation: CacheLocation = 'localStorage';

  const azureAdConfig = getAppConfig().azureAdConfig;
  console.log('azureAdConfig', azureAdConfig);

  const config: Configuration = {
    auth: {
      authority: `https://login.microsoftonline.com/${azureAdConfig.tenantId}`,
      clientId: azureAdConfig.clientId,
      redirectUri: window.location.origin.toString() as string,
    },
    cache: {
      cacheLocation: cacheLocation,
      storeAuthStateInCookie: true,
    },
  };

  const authenticationParameters = {
    scopes: [
      'openid',
    ],
  };

  const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: (window.location.origin.toString() + '/auth.html') as string
  };

  return new MsalAuthProvider(config, authenticationParameters, options);
}

export const getAuthProvider = (): MsalAuthProvider => {
  if (authProvider == null) {
    authProvider = createAuthProvider();
  }
  return authProvider;
}
