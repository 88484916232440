import {
  ConstructionType,
} from '../../types/domain/construction-data.types';

export const FETCH_CONSTRUCTION_TYPES_ATTEMPT = '[Construction Data] FETCH_CONSTRUCTION_TYPES_ATTEMPT';
export const FETCH_CONSTRUCTION_TYPES_SUCCESS = '[Construction Data] FETCH_CONSTRUCTION_TYPES_SUCCESS';
export const FETCH_CONSTRUCTION_TYPES_FAILURE = '[Construction Data] FETCH_CONSTRUCTION_TYPES_FAILURE';

export interface FetchConstructionTypesAttempt {
  type: typeof FETCH_CONSTRUCTION_TYPES_ATTEMPT;
}

export interface FetchConstructionTypesSuccess {
  type: typeof FETCH_CONSTRUCTION_TYPES_SUCCESS;
  payload: ConstructionType[];
}

export interface FetchConstructionTypesFailure {
  type: typeof FETCH_CONSTRUCTION_TYPES_FAILURE;
  error: any;
}

export type ConstructionDataActionTypes =
  | FetchConstructionTypesAttempt
  | FetchConstructionTypesSuccess
  | FetchConstructionTypesFailure;