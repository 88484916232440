import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { hasTechnicalRoleSelector, hasMarketingRoleSelector } from '../../store/user/user.selectors';
import { exportCalculations } from '../../store/calculations-export/calculations-export.action-creators';
import { errorSelector, isLoadingSelector, exportRowCountSelector } from '../../store/calculations-export/calculations-export.selectors';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import DatePickers from './DatePickers';
import { setError } from '../../store/calculations-export/calculations-export.actions';

export default function CalculationsExport() {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const error = useSelector(errorSelector);
  const isLoading = useSelector(isLoadingSelector);
  const exportRowCount = useSelector(exportRowCountSelector);

  const hasTechnicalRole = useSelector(hasTechnicalRoleSelector);
  const hasMarketingRole = useSelector(hasMarketingRoleSelector);

  const hasAccess = hasTechnicalRole || hasMarketingRole;

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const handleExportOnClick = () => {
    if (fromDate === null || toDate === null) {
      dispatch(setError(t('calculations-export-ui.export-validation-error')));
      return;
    }

    dispatch(exportCalculations(fromDate, toDate));
  }

  useEffect(() => {
    if (fromDate !== null && toDate !== null) {
      dispatch(setError(null));
    }
  }, [dispatch, fromDate, toDate]);

  return (
    <Box>
      <h1 data-qa-id='export-calculations-label'><Trans i18nKey={'calculations-export-ui.title'}>Export Calculations</Trans></h1>

      {!hasAccess ? <p><Trans i18nKey={'calculations-export-ui.no-permission'}>no-permission</Trans></p>
        : <div>
          <Box m={2}>
            <DatePickers fromDate={fromDate} toDate={toDate} setFromDate={setFromDate} setToDate={setToDate} />
          </Box>

          {isLoading && <Box m={2}><CircularProgress /></Box>}

          <Box m={2}>
            <Button variant="contained" color="primary" disabled={isLoading} onClick={handleExportOnClick} data-qa-id="export-btn">
              <Trans i18nKey={'calculations-export-ui.export-button'}>Export</Trans>
            </Button>
          </Box>

          {!isEmpty(error) && <Box m={2}><Alert severity="error">{error}</Alert></Box>}

          {exportRowCount && (
            <div data-qa-id="export-message">
              <Box m={2}><Alert severity="success">{t('calculations-export-ui.success', { count: exportRowCount.count as any })}</Alert></Box>

              {exportRowCount.limitReached && <Box m={2}><Alert severity="warning">{t('calculations-export-ui.warning', { count: exportRowCount.count as any })}</Alert></Box>}
            </div>
          )}
        </div>}
    </Box>
  );
};
