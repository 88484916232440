import 'date-fns';
import React, { useState } from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { compareAsc, compareDesc } from 'date-fns';
import { Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';

export type DatePickersProps = {
  fromDate: Date | null;
  toDate: Date | null;
  setFromDate: (date: Date | null) => void;
  setToDate: (date: Date | null) => void;
};

export default function DatePickers(props: DatePickersProps) {

  const { fromDate, toDate, setFromDate, setToDate } = props;

  const { t } = useTranslation();

  const [ error, setError ] = useState<string | null>(null);

  const handleFromDateChange = (date: Date | null) => {
    if (date === null) {
      return;
    }
    
    if (toDate === null) {
      setFromDate(date);
      return;
    }

    if (compareAsc(toDate, date) < 0) {
      setError(t('calculations-export-ui.date-picking-error'));
      return;
    }

    setFromDate(date);
    setError(null);
  };

  const handleToDateChange = (date: Date | null) => {
    if (date === null) {
      return;
    }

    if (fromDate === null) {
      setToDate(date);
      return;
    }
    
    if (compareDesc(fromDate, date) < 0) {
      setError(t('calculations-export-ui.date-picking-error'));
      return;
    }

    setToDate(date);
    setError(null);
  };

  return (
    <Box>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container spacing={4}>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="from-date-picker"
              label={t('calculations-export-ui.from-label')}
              value={fromDate}
              onChange={handleFromDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="to-date-picker"
              label={t('calculations-export-ui.to-label')}
              value={toDate}
              onChange={handleToDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      {!isEmpty(error) && <Alert severity="warning">{error}</Alert>}
    </Box>
  );
}