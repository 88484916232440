import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './components/App/App';
import { setAppConfig } from './config/config';
import { AppConfig } from './types/config/configTypes';

import './i18n';
import axios from 'axios';

(async function() {
  try {
    const clientConfig = await (await fetch('/config.json')).json();
    const { apiUrl } = clientConfig;
    const configData = await fetch(`${apiUrl}/config.json`);
    const appConfig = {
      ...(await configData.json()) as AppConfig,
      ...clientConfig
    };

    console.log('appConfig', appConfig);

    setAppConfig(appConfig);

    // Set the base URL for the http client
    axios.defaults.baseURL = apiUrl;

    ReactDOM.render(<App />, document.getElementById('root'));
  } catch (e) {
    console.error('App config could not be loaded');
    console.error(e);
  }
})();
