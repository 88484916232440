import React, { useState } from 'react';
import { Avatar, Drawer, List, ListItemText,
  ListItem, Box, ListItemIcon, ListItemAvatar,
  IconButton, makeStyles, createStyles, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { deepPurple } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => (
  createStyles({
    signedInAvatar: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: deepPurple[500],
    },
    drawerList: {
      minWidth: 200,
    },
  })
))


export type NavigationMobileProps = {
  isLoggedIn: boolean;
  userName: string;
  handleSignInClick: () => void;
  handleSignOutClick: () => void;
};

export default function NavigationMobile(props: NavigationMobileProps) {

  const { isLoggedIn, userName, handleSignInClick, handleSignOutClick } = props;

  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Box>
      <IconButton onClick={() => setDrawerOpen(true)}><MenuIcon /></IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawerList}>
          <List dense={false}>
            <ListItem button onClick={() => setDrawerOpen(false)}>
              <ListItemIcon><CloseIcon /></ListItemIcon>
              <ListItemText primary="Close"></ListItemText>
            </ListItem>
            {isLoggedIn
              ? 
              <ListItem button onClick={handleSignOutClick}>
                <ListItemAvatar><Avatar src="not-valid.jpg" className={classes.signedInAvatar}>{getInitials(userName)}</Avatar></ListItemAvatar>
                <ListItemText primary='Sign out' secondary={userName}></ListItemText>
              </ListItem>
              :
              <ListItem button onClick={handleSignInClick}>
                <ListItemAvatar><Avatar src="not-valid.jpg" /></ListItemAvatar>
                <ListItemText primary="Sign in"></ListItemText>
              </ListItem>
            }
          </List>
        </div>
      </Drawer> 
    </Box>
  );
}

const getInitials = (name: string): string => {
  return name[0];
};
