import { exportCalculationsAttempt, exportCalculationsSuccess, exportCalculationsFailure } from "./calculations-export.actions"
import { CalculationsExportRowCount } from '../../types/domain/calculations-export-types';
import { getAuthProvider } from "../../authentication/authProvider";
import axios from "axios";

export const exportCalculations = (fromDate: Date, toDate: Date) => (
  async (dispatch: any) => {

    dispatch(exportCalculationsAttempt());

    try {
      const token = await getAuthProvider().getIdToken();
      const response = await axios.get('api/calculations', {
        headers: { 'Authorization': `Bearer ${token.idToken.rawIdToken}` },
        responseType: 'blob',
        params: {
          from: formatDate(fromDate),
          to: formatDate(toDate),
        },
      });

      const exportRowCount: CalculationsExportRowCount = {
        count: `${response.headers['export-row-count']}`,
        limitReached: response.headers['export-row-count-limit-reached'] === 'true'
      };

      // Creating link to the Blob
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `KICS-Customer-Calculations.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      dispatch(exportCalculationsSuccess(exportRowCount));
    } catch(error) {
      dispatch(exportCalculationsFailure(error.response.statusText));
    }

  }
)

const formatDate = (date: Date): string => {
  return `${date.getFullYear()}-${appendLeadingZero(date.getMonth() + 1)}-${appendLeadingZero(date.getDate())}T00:00:00.000Z`;
}

const appendLeadingZero = (n: number): string => n <= 9 ? '0' + n : n.toString();
