import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Layout } from '../Layout/Layout';
import { store } from '../../store/store';
import { theme } from '../../theme/theme';

import { LandingPage } from '../LandingPage/LandingPage';

import logo from '../../assets/images/kingspan-logo.svg';

const Loader = () => (
  <div className="App">
    <img src={logo} className="App-logo" alt="logo" />
    <div>loading...</div>
  </div>
);

export function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loader />}>
          <Layout>
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <LandingPage />
                </Route>
                <Redirect to="/" />
              </Switch>
            </BrowserRouter>
            <ToastContainer />
          </Layout>
        </Suspense>
      </ThemeProvider>
    </Provider>
  );
}
