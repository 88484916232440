import { CalculationsExportRowCount } from '../../types/domain/calculations-export-types';

export const EXPORT_CALCULATIONS_ATTEMPT = '[Calculations Export] Export Calculations Attempt';
export const EXPORT_CALCULATIONS_SUCCESS = '[Calculations Export] Export Calculations Success';
export const EXPORT_CALCULATIONS_FAILURE = '[Calculations Export] Export Calculations Failure';

export const SET_ERROR = '[Calculations Export] Set Error';

export interface ExportCalculationsAttempt {
  type: typeof EXPORT_CALCULATIONS_ATTEMPT;
}

export interface ExportCalculationsSuccess {
  type: typeof EXPORT_CALCULATIONS_SUCCESS;
  payload: CalculationsExportRowCount;
}

export interface ExportCalculationsFailure {
  type: typeof EXPORT_CALCULATIONS_FAILURE;
  error: string;
}

export interface SetError {
  type: typeof SET_ERROR;
  error: string | null;
}

export type CalculationsExportActionTypes =
  | ExportCalculationsAttempt
  | ExportCalculationsSuccess
  | ExportCalculationsFailure
  | SetError;
