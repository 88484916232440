import {StoreModel} from "../store.model";
import {ConstructionTypeNewsItemsModel, NewsItemTableScreenModel} from "../../types/domain/news-items.types";

export const countryIdSelector = (state: StoreModel): string => state.newsItems.countryId;

export const allowedCultureIdsSelector = (state: StoreModel): string[] => state.newsItems.allowedCultures;

export const newsItemTableStateSelector = (state: StoreModel): NewsItemTableScreenModel => state.newsItems.newsItemTableScreen;

export const constructionTypeNewsItemsSelector = (state: StoreModel): ConstructionTypeNewsItemsModel => state.newsItems.constructionTypeNewsItems;
