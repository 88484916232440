import {
  SetCountryId,
  SET_COUNTRY_ID,
  SetConstructionTypeId,
  SET_CONSTRUCTION_TYPE_ID,
  SetError,
  SET_ERROR,
  DownloadMasterDataTemplateAttempt,
  DOWNLOAD_MASTER_DATA_TEMPLATE_ATTEMPT,
  DownloadMasterDataTemplateSuccess,
  DOWNLOAD_MASTER_DATA_TEMPLATE_SUCCESS,
  DownloadMasterDataTemplateFailure,
  DOWNLOAD_MASTER_DATA_TEMPLATE_FAILURE
} from "./master-data-download.action-types";

export const setCountryId = (countryId: string): SetCountryId => ({
  type: SET_COUNTRY_ID,
  payload: countryId,
});

export const setConstructionTypeId = (constructionTypeId: string): SetConstructionTypeId => ({
  type: SET_CONSTRUCTION_TYPE_ID,
  payload: constructionTypeId,
});

export const setError = (error: any): SetError => ({
  type: SET_ERROR,
  error: error,
});

export const downloadMasterDataTemplateAttempt = (): DownloadMasterDataTemplateAttempt => ({
  type: DOWNLOAD_MASTER_DATA_TEMPLATE_ATTEMPT,
});

export const downloadMasterDataTemplateSuccess = (): DownloadMasterDataTemplateSuccess => ({
  type: DOWNLOAD_MASTER_DATA_TEMPLATE_SUCCESS
});

export const downloadMasterDataTemplateFailure = (error: any): DownloadMasterDataTemplateFailure => ({
  type: DOWNLOAD_MASTER_DATA_TEMPLATE_FAILURE,
  error: error,
});
