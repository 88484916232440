import React from 'react';
import Grid from '@material-ui/core/Grid'
import { CreateNewsItemDialog } from "./CreateNewsItemDialog/CreateNewsItemDialog";
import { SelectCountry } from "./SelectCountry/SelectCountry";
import ManageNewsTable from "./NewsManagerTable";
import { EditNewsItemDialog } from "./EditNewsItemDialog/EditNewsItemDialog";
import { useSelector } from "react-redux";
import { hasMarketingRoleSelector } from "../../store/user/user.selectors";
import { Trans } from "react-i18next";
import ManageAssociationTable from "./ManageAssociationTable/ManageAssociationTable";

export default function NewsItems() {
  const [showCreateItemDialog, setShowCreateItemDialog] = React.useState(false);
  const [showEditItemDialog, setShowEditItemDialog] = React.useState(false);
  const hasMarketingRole = useSelector(hasMarketingRoleSelector);

  if (!hasMarketingRole) return <p><Trans i18nKey={'news-items-ui.no-permission'}>no-permission</Trans></p>;

  return (
    <div>
      <SelectCountry />
      <Grid container>
        {showCreateItemDialog && <CreateNewsItemDialog onClose={() => setShowCreateItemDialog(false)} />}
        {showEditItemDialog && <EditNewsItemDialog onClose={() => setShowEditItemDialog(false)} />}
        <ManageNewsTable
          setShowCreateItemDialog={setShowCreateItemDialog}
          setEditCreateItemDialog={setShowEditItemDialog}
        />
        <ManageAssociationTable />
      </Grid>
    </div>
  );
}
