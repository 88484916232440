import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { getCountries } from '../../common/countries';
import { CountryModel } from '../Countries/CountryModel';

export type CountrySelectionPropType = {
  countryIds: string[];
  selectedCountryId: string;

  handleChange: (event: React.ChangeEvent<{ value: unknown; }>) => void;
};

export default function CountrySelection(props: CountrySelectionPropType) {

  const { t } = useTranslation();
  const [countries, setCountries] = useState<CountryModel[]>([]);

  useEffect(() => {
    getCountries().then(result => {
      setCountries(result.data);
    });
  }, []);

  return isEmpty(props.countryIds)
    ? <div>{t('country-selection-ui.no-country-permissions')}</div>
    : <div>
      <InputLabel id="region-select-label"><Trans i18nKey={'country-selection-ui.select-label'}>Country</Trans></InputLabel>
      <Select
        labelId="region-select-label"
        data-qa-id="adminRegionSelect"
        displayEmpty
        value={props.selectedCountryId}
        onChange={props.handleChange}        
        renderValue={() => {
          if (props.selectedCountryId.length === 0) {
            return <em>{t('country-selection-ui.select-default')}</em>;
          }
          return countries.find(c => c.countryId === props.selectedCountryId)?.name;
        }}
      >
        <MenuItem disabled value="">
          <em>{t('country-selection-ui.select-default')}</em>
        </MenuItem>

        {props.countryIds.map(countryId => ({
          countryId,
          name: countries.find(c => c.countryId === countryId)?.name ?? countryId ?? ''
        })).sort((a, b) => a.name.localeCompare(b.name)).map(c => (
          <MenuItem key={c.countryId} value={c.countryId}>
            {c.name}
          </MenuItem>
        ))}
      </Select>
    </div>;
}
