import { StoreModel, UserModel } from '../store.model';
import { AdminRole } from '../../authentication/roles';

export const countryIdsSelector = (state: StoreModel): string[] => state.user.countryIds;

export const hasTechnicalRoleSelector = (state: StoreModel): boolean => hasAdminRoleSelector(state, AdminRole.TECHNICAL);

export const hasMarketingRoleSelector = (state: StoreModel): boolean => hasAdminRoleSelector(state, AdminRole.MARKETING);

export const hasAdminRoleSelector = (state: StoreModel, adminRole: AdminRole): boolean =>
  state.user.allRoleClaims.some((role) => role === adminRole);

export const userSelector = (state: StoreModel): UserModel => state.user;
