import React, { useState } from 'react';
import { Button, Box, Divider, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { uploadMasterData } from '../../store/master-data-upload/master-data-upload.action-creators';
import { hasTechnicalRoleSelector } from '../../store/user/user.selectors';
import {
  isLoadingSelector,
  responseSelector,
  isLoadingCommitSelector,
  committedSuccessfullySelector,
  errorCommitSelector
} from '../../store/master-data-upload/master-data-upload.selectors';
import { Trans } from 'react-i18next';
import { isEmpty } from 'lodash';
import CommitMessage from './CommitMessage';
import ValidationMessage from './ValidationMessage';
import ChooseFile from './ChooseFile';

export default function MasterDataUpload() {

  const dispatch = useDispatch();

  const hasTechnicalRole = useSelector(hasTechnicalRoleSelector);
  const isLoading = useSelector(isLoadingSelector);
  const response = useSelector(responseSelector);
  const isLoadingCommit = useSelector(isLoadingCommitSelector);
  const committedSuccessfully = useSelector(committedSuccessfullySelector);
  const errorCommit = useSelector(errorCommitSelector);

  const [fileName, setFileName] = useState('');
  const [fileAsBlob, setFileAsBlob] = useState('');

  const readyToUpload = () => fileAsBlob !== '';

  const handleChooseFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setFileName(event.target.files[0].name);
      setFileAsBlob(event.target.files[0]);
      dispatch(uploadMasterData(event.target.files[0], event.target.files[0].name));

      // Clear out the HTML input control's selected file, allowing the user to select a new file, or even reselect the same file.
      // This does not clear out the blob reference we have, and as long as the file doesn't change on disk before commiting, the commit changes still works.
      event.target.value = null;
    }
  };

  const handleCommitChangesOnClick = () => {
    if (readyToUpload()) {
      const commit = true;
      dispatch(uploadMasterData(fileAsBlob, fileName, commit));
    }
  };

  return (
    <div>
      <h1 data-qa-id="titleLabel"><Trans i18nKey={'master-data-upload-ui.header'}>Upload master data</Trans></h1>

      {!hasTechnicalRole ? <p><Trans i18nKey={'master-data-upload-ui.no-permission'}>No Permission</Trans></p>
        : <div>
          <Box m={2}>
            <ChooseFile
              fileName={fileName}
              committedSuccessfully={committedSuccessfully}
              isLoading={isLoading}
              isSecondary={!isEmpty(response.ok)}
              handleChange={handleChooseFileChange} />
          </Box>

          {committedSuccessfully && <Box m={2}><Alert severity="success" data-qa-id="success-label">Your master data changes have been applied successfully</Alert></Box>}

          {!isEmpty(response.ok) &&
            <Box>
              <Divider />
              <Box m={2}>
                <CommitMessage {...response.ok} />
              </Box>

              {isLoadingCommit && <Box m={2}><CircularProgress /></Box>}

              <Box m={2}>
                <Button variant="contained" color="primary" onClick={handleCommitChangesOnClick} data-qa-id="commit-changes-btn">Commit changes</Button>
              </Box>

              {!isEmpty(errorCommit) && <Box m={2}><Alert severity="error">{errorCommit.message}</Alert></Box>}
            </Box>
          }

          {!isEmpty(response.badRequest) &&
            <Box>
              <Divider />
              <Box m={2}>
                <ValidationMessage {...response.badRequest} />
              </Box>
            </Box>
          }
        </div>}

    </div>
  );
};
