import {NewsItemsModel} from '../store.model';

import {
  GET_CONSTRUCTION_TYPE_NEWS_ITEMS_ATTEMPT,
  GET_CONSTRUCTION_TYPE_NEWS_ITEMS_FAILURE,
  GET_CONSTRUCTION_TYPE_NEWS_ITEMS_SUCCESS,
  GET_NEWS_ITEM_ATTEMPT,
  GET_NEWS_ITEM_FAILURE,
  GET_NEWS_ITEM_SUCCESS,
  NewsItemsActionTypes,
  SET_ALLOWED_CULTURE_IDS,
  SET_COUNTRY_ID,
  SET_ERROR
} from './news-items.action-types';

export const initialState: NewsItemsModel = {
  isLoading: false,
  error: null,
  countryId: "",
  allowedCultures: [],
  newsItemTableScreen: {
    isLoading: false,
    error: null,
    success: false,
    newsItemsData: []
  },
  constructionTypeNewsItems: {
    isLoading: false,
    error: null,
    success: false,
    constructionTypeNewsItemsData: [],
  }
};

export function newsItemsReducer(state = initialState, action: NewsItemsActionTypes): NewsItemsModel {
  switch (action.type) {
    case SET_COUNTRY_ID:
      return {
        ...state,
        countryId: action.payload,
      };
    case SET_ALLOWED_CULTURE_IDS:
      return {
        ...state,
        allowedCultures: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case GET_NEWS_ITEM_ATTEMPT:
      return {
        ...state,
        newsItemTableScreen: {
          ...state.newsItemTableScreen,
          isLoading: true,
          success: false,
        },
      };
    case GET_NEWS_ITEM_SUCCESS:
      return {
        ...state,
        newsItemTableScreen: {
          ...state.newsItemTableScreen,
          error: null,
          isLoading: false,
          success: true,
          newsItemsData: action.payload
        }
      };
    case GET_NEWS_ITEM_FAILURE:
      return {
        ...state,
        newsItemTableScreen: {
          ...state.newsItemTableScreen,
          isLoading: false,
          success: false,
          error: action.error,
        },
      };

    case GET_CONSTRUCTION_TYPE_NEWS_ITEMS_ATTEMPT:
      return {
        ...state,
        constructionTypeNewsItems: {
          ...state.constructionTypeNewsItems,
          isLoading: true,
          success: false,
        },
      };
    case GET_CONSTRUCTION_TYPE_NEWS_ITEMS_SUCCESS:
      return {
        ...state,
        constructionTypeNewsItems: {
          ...state.constructionTypeNewsItems,
          error: null,
          isLoading: false,
          success: true,
          constructionTypeNewsItemsData: action.payload
        }
      };
    case GET_CONSTRUCTION_TYPE_NEWS_ITEMS_FAILURE:
      return {
        ...state,
        constructionTypeNewsItems: {
          ...state.constructionTypeNewsItems,
          isLoading: false,
          success: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
