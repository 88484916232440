import axios from 'axios';
import { getAuthProvider } from '../../authentication/authProvider';
import {
  fetchConstructionTypesAttempt,
  fetchConstructionTypesSuccess,
  fetchConstructionTypesFailure
} from './construction-data.actions';

export const fetchConstructionTypes = (countryId: string) => (

  // TODO: Typing needs to be defined
  async (dispatch: (action: any) => void) => {
    dispatch(fetchConstructionTypesAttempt());

    try {
      const token = await getAuthProvider().getIdToken();
      const { data } = await axios.get('/api/constructionTypes/' + countryId, {
        headers: { 'Authorization': `Bearer ${token.idToken.rawIdToken}` }
      });

      // TODO: We might want to group the construction types by category and save it that way to the store
      // Here is the code but it's not in use just yet
      // _____________________
      // const constructionTypesByCategory = groupBy(data, (constructionType: ConstructionType) => constructionType.category);
      // console.log('ConstructionTypes grouped by category', constructionTypesByCategory);

      dispatch(fetchConstructionTypesSuccess(data));
    } catch (error) {
      dispatch(fetchConstructionTypesFailure(error));
    }
  }
);
